import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import axios from 'axios'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect:'/home'
  },
  {
    path: '/home/:gridid',
    name: 'Home',
    component: Home
  },
  {
    path: '/feedback/:gridid',
    name: 'Feedback',
    component: () => import(/* webpackChunkName: "data" */ '../views/Feedback.vue')
  },
  {
    path:"/introduction",
    name:'Introduction',
    component: () => import(/* webpackChunkName: "data" */ '../views/Introduction.vue')
  },
  {
    path:"/gridperson/:person/:gridid",
    name:'GridPerson',
    component: () => import(/* webpackChunkName: "data" */ '../views/GridPerson.vue')
  },
  {
    // path:"/noticedetail/:gridid/:noticeid",
    // path:"/noticedetail",
    path:"/noticedetail/:noticeid",
    name:'NoticeDetail',
    component: () => import(/* webpackChunkName: "data" */ '../views/NoticeDetail.vue')
  },
  {
    path:"/staticinfo/:gridid",
    name:"StaticInfo",
    component: () => import(/* webpackChunkName: "data" */ '../views/StaticInfo.vue')
  }
]

const router = new VueRouter({
  mode:"history",
  routes
})

// 路由守卫
router.beforeEach((to, from, next) => {

  // 获取重定向的地址
  function GetUrlRelativePath(){
    let url=window.location.href;
    let arrUrl = url.split("//");

    let start = arrUrl[1].indexOf("/");
    let relUrl = arrUrl[1].substring(start);//stop省略，截取从start开始到结尾的所有字符
    if(relUrl.indexOf("#") != -1){
      return relUrl.substring(relUrl.indexOf("#")+1);
    }
    else{
      return relUrl;
    }
  }
  var state=GetUrlRelativePath();
  console.log('indexjs路由守卫获取的重定向相对路径是：',state);
  // alert(state);

  //检查用户授权登录
  function chkcookies()
  {
    let NameOfCookie="SESSIONID";
    let c = document.cookie.indexOf(NameOfCookie+"=");
    if (c != -1)
    {
      console.log('indexjs路由守卫cookie值查到，你已经登录，无需弹窗！')
      return true;
    }
    else
    {
      console.log('在indexjs路由守卫时没有登录！');
      return false;
    }
    
  }
  let isAuthenticated=chkcookies();
  if (!isAuthenticated){
    window.location.replace(`https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxfcd2048b5f2ed215&redirect_uri=https%3A%2F%2Fdoor.bjseeyoung.com%2Fapi%2Fwx%2Flogin&response_type=code&scope=snsapi_userinfo&state=${state}#wechat_redirect`);
  }

  // 如果用户已经登录，获取用户信息，并且保存，然后跳转
  function chkuserinfo()
  {
    if(sessionStorage.getItem("nickname")){
      return true;
    }
    return false;
  }
  let hasUserInfo=chkuserinfo();
  if(hasUserInfo)
  {
    console.log('indexjs路由守卫用户信息已经查询到，无需网络请求！')
    next();
  }
  else
  {
    // 请求用户信息
    axios.get("https://door.bjseeyoung.com/api/wx/user/info")
    .then((response) => {
      console.log("indexjs路由守卫返回的用户信息是：",response.data)
      let avatar = response.data.data.avatar;
      let nickname = response.data.data.nickname;
      let role = response.data.data.role;
      sessionStorage.setItem("nickname", nickname);
      sessionStorage.setItem("avatar", avatar);
      sessionStorage.setItem("role", role);
      next();
    })
    .catch(function (error) {
      console.log("indexjs路由守卫错误信息：",error);
      next(false);
    });
  }
})

router.afterEach((to,from,next)=>{
  window.scrollTo(0,0)
})

export default router
