import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant'
import axios from 'axios'
import 'vant/lib/index.css'
import 'amfe-flexible'
import 'amfe-flexible/index.js'
import './styles/index.less'
import * as echarts from 'echarts';

Vue.prototype.$echarts = echarts

Vue.config.productionTip = false
Vue.use(Vant)


// 注册微信JSSDK
async function querywxData(){
  try {
    let res = await axios.get("https://door.bjseeyoung.com/api/wx/js/ticket",{
      params: { 
        url:location.href
     }
    });
    return res
  } catch (err) {
    console.log("权限数据返回出错",err)
  }
}
querywxData().then(function(res){
  console.log('调用授权接口返回的数据是：',res.data);
  wx.config({
    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
    appId: res.data.data.app_id, // 必填，公众号的唯一标识
    timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
    nonceStr: res.data.data.nonce, // 必填，生成签名的随机串
    signature: res.data.data.signature,// 必填，签名
    jsApiList: [
      "getLocation",
      "updateAppMessageShareData", // 自定义“分享给朋友”及“分享到QQ”按钮的分享内容（1.4.0）
      "updateTimelineShareData", // 自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容（1.4.0）
      "onMenuShareWeibo", // 分享到腾讯微博
      "onMenuShareTimeline", // 分享到朋友圈
      "onMenuShareAppMessage", // 分享给朋友
      "onMenuShareQQ", // 分享到QQ
      "onMenuShareQZone", // 分享到QQ空间
  ], // 必填，需要使用的JS接口列表
    success: function(res_) {
      console.log('jssdk注册成功：',res_);
      },
    fail: function(res_){
      console.log('jssdk注册失败：',res_);
    }
  });

  new Vue({
    router,
    store,
    render: h => h(App),
    beforeCreate(){
      // console.log();
    },
    created(){
  
    },
    mounted(){
      // this.$router.push({ path: '/home' })
    }
  }).$mount('#app')
})

//获取用户地理位置信息
wx.ready(function () {
  wx.checkJsApi({
      jsApiList: [
          "getLocation",
          "updateAppMessageShareData", // 自定义“分享给朋友”及“分享到QQ”按钮的分享内容（1.4.0）
          "updateTimelineShareData", // 自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容（1.4.0）
          "onMenuShareWeibo", // 分享到腾讯微博
          "onMenuShareTimeline", // 分享到朋友圈
          "onMenuShareAppMessage", // 分享给朋友
          "onMenuShareQQ", // 分享到QQ
          "onMenuShareQZone", // 分享到QQ空间
      ],
      success: function (res) {
          if (res.checkResult.getLocation == false) {
              alert('你的微信版本太低，不支持微信JS接口，请升级到最新的微信版本！');
              return;
          }
      }
  });
  wx.error(function(res){
      console.log("微信手机接口调取失败")
  });
  wx.getLocation({
    type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
    success: function (res) {
      console.log('用户地理位置获取成功：', res);
      var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
      var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
      var speed = res.speed; // 速度，以米/每秒计
      var accuracy = res.accuracy; // 位置精度
    },
    fail: function(res){
      console.log('用户地理位置获取失败：', res);
    }
  });
});



// new Vue({
//   router,
//   store,
//   render: h => h(App),
//   beforeCreate(){
//     // console.log();
//   },
//   created(){

//   },
//   mounted(){
//     this.$router.push({ path: '/home/1396762938953695232' })
//   }
// }).$mount('#app')